import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
	Button,
	Card,
	Segment,
	Grid,
	Divider,
	Modal,
	List,
	Header,
	Image,
} from 'semantic-ui-react'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'

import { supplierLogin } from 'store/actions/app'

import LogoImg from '../../images/logo1024.png'

const SupplierLogin = (props) => {
	const [openModal, setOpenModal] = useState(false)

	return (
		<div
			style={{
				width: '100wh',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment raised size='small'>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Image
										src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
										size='medium'
									/>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Formik
										initialValues={{}}
										onSubmit={(values, { setSubmitting }) => {
											props.supplierLogin(values)
										}}
									>
										{({ isSubmitting }) => (
											<Form className='ui form'>
												<Card centered>
													<Card.Content>
														<Card.Header textAlign='center'>
															Sitio del Proveedor
														</Card.Header>
														<Card.Description>Iniciar Sesión</Card.Description>
													</Card.Content>
													<Card.Content>
														<Field
															defaultInput
															type='text'
															component={Input}
															label='Correo electrónico'
															name='user'
														/>
														<Field
															component={Input}
															label='Contraseña'
															name='password'
															type='password'
														/>

														<Button
															style={{
																margin: '1rem 0',
																width: '100%',
															}}
															className='general-color'
															type='submit'
															content={'Iniciar Sesión'}
														/>
													</Card.Content>
												</Card>
											</Form>
										)}
									</Formik>
								</Grid.Row>
								<Divider></Divider>
								<Grid.Row verticalAlign='middle' textAlign='right'>
									<Grid.Column floated='right'>
										<Button
											icon='question'
											circular
											color='black'
											type='button'
											onClick={() => {
												setOpenModal(!openModal)
											}}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Modal
				closeIcon
				basic
				centered={true}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				open={openModal}
				size='large'
			>
				<Modal.Content content>
					<Segment inverted style={{ height: '100%' }}>
						<Grid stackable divided inverted>
							<Grid.Row columns={2}>
								<Grid.Column
									width={6}
									verticalAlign='middle'
									textAlign='center'
								>
									<Header inverted content='© Geologistic GPS 2025' />
								</Grid.Column>
								<Grid.Column width={8}>
									<Header inverted content='Contacto' />
									<Divider></Divider>
									<Header
										size='small'
										inverted
										content='¿Necesitas registrar un nuevo ticket o solicitud?'
									/>
									<List divided inverted>
										<List.Item
											header='Ingresa al Portal: '
											content='https://geologistic.com.mx/Soporte/'
											style={{ cursor: 'pointer' }}
											onClick={() => {
												window.open(
													'https://geologistic.com.mx/Soporte/',
													'_blank'
												)
											}}
										/>
										<List.Item
											header='Soporte'
											content='(686) 214 5447, (686) 385 6938,  (686) 510 9915'
										/>
										<List.Item header='Oficina' content='(686) 372 9877' />
										<List.Item
											header='Emergencias'
											content='(686) 420 3333, (686) 214 5447, (686) 385 6938, (686) 510 9915,  (686) 123 6454'
										/>
										<List.Item
											header='Administración'
											content='(686) 122 0316'
										/>
										<List.Item
											header='Ventas'
											content='(686) 510 9915, (686) 196 4736, (686) 420 8586,  (686) 191 1571'
										/>
									</List>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Modal.Content>
			</Modal>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	supplierLogin: (parmas) => dispatch(supplierLogin(parmas)),
})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierLogin)
