import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Search } from 'semantic-ui-react'
import filter from 'lodash/filter'
import escapeRegExp from 'lodash/escapeRegExp'

const initialState = {
	loading: false,
	results: [],
	value: '',
	itemSelected: null,
	open: false,
}

function exampleReducer(state, action) {
	switch (action.type) {
		case 'CLEAN_QUERY':
			return initialState
		case 'START_SEARCH':
			return { ...state, loading: true, value: action.query, open: true }
		case 'FINISH_SEARCH':
			return { ...state, loading: false, results: action.results }
		case 'UPDATE_SELECTION':
			return {
				...state,
				value: action.selection.title,
				itemSelected: action.selection,
				open: false,
			}
		case 'TOOGLE_SEARCH':
			return { ...state, open: action.open }
		case 'CLEAN_VALUE':
			return { ...state, value: '', open: false }
		case 'SET_DEFAULT_VALUE':
			return { ...state, value: action.value }

		default:
			throw new Error()
	}
}

const CustomSearch = (props) => {
	const {
		options,
		onSelectOption,
		onNotFoundOption,
		onChange,
		clearSelection,
		tabIndex,
		initialValueIndex,
		minCharacters,
		willFocus = false,
		clearOnEmpty = false,
		onBlur,
		searchOnExit = true,
	} = props

	const [enterWasPressedWhileSearching, setEnterWasPressedWhileSearching] =
		useState(false)

	const [state, dispatch] = useReducer(exampleReducer, initialState)
	const { loading, results, value, open } = state

	const timeoutRef = React.useRef()
	const mainRef = React.useRef(null)

	const handleSearchChange = React.useCallback(
		(e, data) => {
			clearTimeout(timeoutRef.current)
			dispatch({ type: 'START_SEARCH', query: data.value })

			timeoutRef.current = setTimeout(() => {
				if (data.value.length === 0 && clearOnEmpty) {
					dispatch({ type: 'CLEAN_QUERY' })
					return
				}

				const re = new RegExp(escapeRegExp(data.value), 'i')
				let isMatch = (result) =>
					re.test(result.title) || re.test(result.description)

				dispatch({
					type: 'FINISH_SEARCH',
					results: filter(options, isMatch),
				})
			}, 300)

			if (onChange) {
				onChange(data.value)
			}
		},
		[options]
	)

	useEffect(() => {
		if (willFocus) {
			mainRef.current.focus()
			dispatch({
				type: 'TOOGLE_SEARCH',
				open: true,
			})
		}
	}, [willFocus])

	useEffect(() => {
		dispatch({
			type: 'FINISH_SEARCH',
			results: options,
		})
	}, [options])

	useEffect(() => {
		if (!loading) {
			if (enterWasPressedWhileSearching) {
				handleKeyDownOnFinishSearch()
				setEnterWasPressedWhileSearching(false)
			}
		}
	}, [loading, enterWasPressedWhileSearching])

	useEffect(() => {
		dispatch({
			type: 'SET_DEFAULT_VALUE',
			value: props.value,
		})
	}, [props.value])

	const clear = () => {
		dispatch({
			type: 'CLEAN_VALUE',
		})
	}

	if (clearSelection) {
		clearSelection(clear)
	}

	useEffect(() => {
		if (initialValueIndex >= 0) {
			const option = options[initialValueIndex]
			if (option) {
				dispatch({
					type: 'UPDATE_SELECTION',
					selection: option,
					item: option,
				})
			}
		}
	}, [initialValueIndex])

	const handleKeyDownOnFinishSearch = () => {
		if (results.length === 1) {
			const result = results[0]
			onSelectOption(result, clear)
			dispatch({
				type: 'CLEAN_QUERY',
			})

			document.activeElement.blur()
			setTimeout(() => {
				mainRef.current.focus()
			}, 100)
		} else if (results.length === 0) {
			onNotFoundOption(value, clear)
		}
	}

	const onKeyDown = (e) => {
		const isEnter = e.keyCode === 13 || (e.keyCode === 9 && searchOnExit)

		if (isEnter) {
			if (loading) {
				setEnterWasPressedWhileSearching(true)
			} else {
				handleKeyDownOnFinishSearch(results)
			}

			e.preventDefault()
			e.stopPropagation()
		}
	}

	return (
		<Search
			open={open}
			className={props.className}
			loading={loading}
			tabIndex={tabIndex}
			input={{ ref: mainRef, placeholder: "Buscar proceso" }}
			onResultSelect={(e, data) => {
				dispatch({
					type: 'UPDATE_SELECTION',
					selection: data.result.title,
					item: data.result,
				})
				onSelectOption(data.result, clear)
			}}
			onSearchChange={handleSearchChange}
			results={results}
			value={value}
			minCharacters={minCharacters}
			onFocus={() => {
				mainRef.current.focus()
				dispatch({
					type: 'TOOGLE_SEARCH',
					open: true,
				})
			}}
			onBlur={(e, data) => {
				if (onBlur) onBlur(data.value)
				dispatch({
					type: 'TOOGLE_SEARCH',
					open: false,
				})
			}}
			onKeyDown={onKeyDown}
		/>
	)
}

CustomSearch.propTypes = {
	onSelectOption: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	minCharacters: PropTypes.number,
	value: PropTypes.string,
}

export default CustomSearch
