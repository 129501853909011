import crud from './_crud'
import { callApi } from 'utils/api'
import { showSuccessMessage } from 'utils/messages'
import { createHeaders, getRol } from 'utils/rol'

const loadPassengersBindigns = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/wialon/passengers_bindings`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.user.token,
			},
		})

		// dispatch({
		// 	type: `GET_${entity.toUpperCase()}S_WIALON`,
		// 	response: res.body,
		// })

		return res.body
	}
}

const loadWialonPassengers = () => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/wialon/passengers`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		dispatch({
			type: `GET_PASSENGERS_WIALON`,
			response: res.body,
		})

		return res.body
	}
}

const loadClientPassengers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client_passenger`, {
			method: 'GET',
			headers: {
				Authorization: state.app.client.token,
			},
			params: options?.params,
		})

		dispatch({
			type: `LOAD_PASSENGERS`,
			response: res.body,
		})

		return res.body
	}
}

const loadPassengers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger_site`, {
			method: 'GET',
			headers: {
				Authorization: state.app.passenger.token,
			},
		})

		dispatch({
			type: `	LOAD_PASSENGER`,
			response: res.body,
		})

		return res.body
	}
}

const getClientPassenger = (id, options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()
		dispatch({
			type: `FETCH_PASSENGERS`,
		})
		const res = await callApi(`/client_passenger/${id}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.client.token,
			},
			params: options.params,
		})

		dispatch({
			type: `LOAD_PASSENGER`,
			response: res.body,
			payload: id,
		})

		return res.body
	}
}

const getPassenger = (id, options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()
		dispatch({
			type: `FETCH_PASSENGERS`,
		})
		const res = await callApi(`/passenger_site/${id}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.passenger.token,
			},
			params: options.params,
		})

		dispatch({
			type: `LOAD_PASSENGER`,
			response: res.body,
			payload: id,
		})

		return res.body
	}
}

const addClientPassenger = (item, options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()
		//  It first add the Item

		let res = await callApi(`/client_passenger`, {
			method: 'POST',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.client.token,
			},
			successMessage: `Se agregó el pasajero correctamente.`,
		})

		let stored_item = res.body
		return dispatch({
			type: `ADD_PASSENGER`,
			response: stored_item,
		})
	}
}

const addPassenger = (item, options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/passenger_site`, {
			method: 'POST',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.passenger.token,
			},
			successMessage: `Se agregó el pasajero correctamente.`,
		})

		let stored_item = res.body
		return dispatch({
			type: `ADD_PASSENGER`,
			response: stored_item,
		})
	}
}

const removeClientPassenger = (item) => {
	return async (dispatch, getState) => {
		const state = getState()
		const res = await callApi(`/client_passenger/${item.id}`, {
			method: 'DELETE',
			headers: {
				Authorization: state.app.client.token,
			},
			successMessage: `Se eliminó el pasajero correctamente.`,
		})
		dispatch({
			type: `DELETE_PASSENGER`,
			response: res.body,
		})
	}
}

const removePassenger = (item) => {
	return async (dispatch, getState) => {
		const state = getState()
		const res = await callApi(`/passenger_site/${item.id}`, {
			method: 'DELETE',
			headers: {
				Authorization: state.app.passenger.token,
			},
			successMessage: `Se eliminó el pasajero correctamente.`,
		})
		dispatch({
			type: `DELETE_PASSENGER`,
			response: res.body,
		})
	}
}

const updateClientPassenger = (item, shouldShowSuccessMessage = true) => {
	return async (dispatch, getState) => {
		const state = getState()
		let res = await callApi(`/client_passenger/${item.id}`, {
			method: 'PATCH',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.client.token,
			},
			successMessage:
				shouldShowSuccessMessage && `Se actualizó el pasajero correctamente.`,
		})

		let stored_item = res.body

		dispatch({
			type: `UPDATE_PASSENGER`,
			response: stored_item,
		})
	}
}

const updatePassenger = (item, shouldShowSuccessMessage = true) => {
	return async (dispatch, getState) => {
		const state = getState()
		let res = await callApi(`/passenger_site/${item.id}`, {
			method: 'PATCH',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.passenger.token,
			},
			successMessage:
				shouldShowSuccessMessage && `Se actualizó el pasajero correctamente.`,
		})

		let stored_item = res.body

		dispatch({
			type: `UPDATE_PASSENGER`,
			response: stored_item,
		})
	}
}
const bulkSyncUpdate = (updateItems) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/passenger/bulkSyncUpdate`, {
			method: 'PUT',
			body: JSON.stringify({
				updateItems,
			}),
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: `Se sincronizaron los pasajeros correctamente.`,
		})

		let stored_item = res.body
		return stored_item
	}
}

const bulkSyncUpdateClientPassenger = (updateItems) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/client_passenger/bulkSyncUpdateClientPassenger`, {
			method: 'PUT',
			body: JSON.stringify({
				updateItems,
			}),
			headers: {
				Authorization: state.app.client.token,
			},
			successMessage: `Se sincronizaron los pasajeros correctamente.`,
		})

		let stored_item = res.body
		return stored_item
	}
}

const bulkSyncClientPassenger = ({ deleteItems, updateItems, createItems }) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/client_passenger/bulkSync`, {
			method: 'POST',
			body: JSON.stringify({
				deleteItems,
				updateItems,
				createItems,
			}),
			headers: {
				Authorization: state.app.client.token,
			},
			successMessage: `Se sincronizaron los pasajeros correctamente.`,
		})

		return dispatch(loadClientPassengers())
	}
}

const bulkSyncPassenger = ({ deleteItems, updateItems, createItems }) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/passenger/bulkSync`, {
			method: 'POST',
			body: JSON.stringify({
				deleteItems,
				updateItems,
				createItems,
			}),
			headers: {
				Authorization: state.app.passenger.token,
			},
			successMessage: `Se sincronizaron los pasajeros correctamente.`,
		})

		return dispatch(loadPassengers())
	}
}
const createAddFile = (item) => {
	return async (dispatch, getState) => {
		const state = getState()
		console.log(state)

		const res = await callApi(`/passenger_site/create_add_file`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.passenger.token, //aqui
			},
			body: {
				travel_files: item.tickets ? item.tickets : null,
				item: JSON.stringify(item),
			},
		})
		//dispatch(getPassenger(res.body.id))
		showSuccessMessage('El archivo se registró correctamente')
		return res.body
	}
}

const updateFile = (item) => {
	return async (dispatch, getState) => {
		const state = getState()
		let filePhotos = []
		if (item?.tickets) {
			item.photo = true
			filePhotos.push(...item.tickets)
		}
		const res = await callApi(`/passenger_site/update_file`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.passenger.token,
			},
			body: {
				files: filePhotos.length > 0 ? filePhotos : undefined,
				photo: item?.photo ? true : false,
				id: item.id,
				item: JSON.stringify(item),
			},
		})
		dispatch(actions.get(res.body.id))
		showSuccessMessage('El archivo se actualizo correctamente')
		return res.body
	}
}

const importPassengers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger/import_passengers`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los pasajeros correctamente.`,
			errorMessage: `Hubo un error al importar los pasajeros.`,
		})

		// console.log('Se terminó de importar')

		// if (res.body.some((p) => p.errors)) {
		// 	dispatch({
		// 		type: 'IMPORT',
		// 		payload: res.body,
		// 	})
		// } else {
		// 	showSuccessMessage('Se registraron los pasajeros correctamente')
		// }

		return res.body
	}
}

export const clientExcelLayout = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger/utils/client_generate_layout_excel`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.client.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

export const clientImportFile = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/passenger/utils/client_import_data_from_excel`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.client.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const clientVerifyImportData = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/passenger/utils/client_validate_data_from_import`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.client.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const clientImportPassengers = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger/utils/client_import_passengers`, {
			method: 'POST',
			headers: {
				Authorization: state.app.client.token,
			},
			body: JSON.stringify({
				options,
			}),
		})

		// if (res.body.some((p) => p.errors)) {
		// 	dispatch({
		// 		type: 'IMPORT',
		// 		payload: res.body,
		// 	})
		// } else {
		// 	showSuccessMessage('Se registraron los pasajeros correctamente')
		// }
		showSuccessMessage('Se registraron los pasajeros correctamente')
		return res.body
	}
}

const getPassengerPermissions = (passengerId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger_permits/${passengerId}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const savePassengerPermissions = (permissions, passengerId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/passenger_permits/${passengerId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ permissions }),
		})

		return res.body
	}
}

const createPassengerWithImage = (item, image) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/passenger/create_passenger_with_image`, {
				method: 'POST',
				json: false,
				body: {
					item: JSON.stringify(item),
					image: image,
				},
				headers: {
					Authorization: state.app.user.token,
				},
				successMessage: `Se agregó el pasajero correctamente.`,
			})

			return res.body
		} catch (e) {
			return
		}
	}
}

const updatePassengerWithImage = (item, image) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/passenger/update_passenger_with_image`, {
				method: 'POST',
				json: false,
				body: {
					item: JSON.stringify(item),
					image: image,
				},
				headers: {
					Authorization: state.app.user.token,
				},
				successMessage: `Se actualizó el pasajero correctamente.`,
			})

			return res.body
		} catch (e) {
			return
		}
	}
}

const loadPersonalTransportationParameters = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/personal_transportation_parameters/utils/load_personal_transportation_parameters`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: options?.params,
		})

		return res.body
	}
}

const getPassengerModules = () => {
	return async (dispatch, getState) => {
		const state = getState();

		const rol = getRol(state);
		const headers = createHeaders(state, rol);

		const res = await callApi(`/passenger_permits/passenger-modules`, {
			method: 'GET',
			headers,
		});

		return res.body;
	};
};

const getWebPassengerPermissions = (passengerId) => {
	return async (dispatch, getState) => {
		const state = getState();

		const rol = getRol(state);
		const headers = createHeaders(state, rol);

		const res = await callApi(`/passenger_permits/web/${passengerId}`, {
			method: 'GET',
			headers,
		});

		return res.body;
	};
};

const saveWebPassengerPermissions = (permissions, passengerId) => {
	return async (dispatch, getState) => {
		const state = getState();

		const res = await callApi(`/passenger_permits/web/${passengerId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ web_permissions: permissions }),
		});

		return res.status === 200;
	};
};

const createWialonPassengerNotification = (passenger_id) => {
	return async (dispatch, getState) => {
		const state = getState();

		const res = await callApi(
			`/client_passenger/create_wialon_passenger_notification`,
			{
				method: 'POST',
				headers: {
					Authorization: state.app.client.token,
				},
				body: JSON.stringify({ passenger_id }),
				successMessage: 'Notificación creada correctamente',
			}
		)

		dispatch({
			type: `LOAD_PASSENGER`,
			response: res.body,
		})
	};
};

const getWialonNotificationStatus = (passengerId, notificationId) => {
	return async (dispatch, getState) => {
		const state = getState();

		const res = await callApi(
			`/client_passenger/get_wialon_notification_status/${passengerId}/${notificationId}`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.client.token,
				},
			}
		)

		if ( typeof res.body === 'object' ) {
			dispatch({
				type: `LOAD_PASSENGER`,
				response: res.body,
			})
		} else {
			return res.body
		}


	}
}

const toggleWialonNotificationStatus = (notification) => {
	return async (dispatch, getState) => {
		const state = getState();

		const res = await callApi(
			`/client_passenger/change_wialon_notification_status`,
			{
				method: 'POST',
				headers: {
					Authorization: state.app.client.token,
				},
				body: JSON.stringify(notification),
				successMessage: notification.notification_status
					? 'Notificación habilitada correctamente'
					: 'Notificación deshabilitada correctamente',
			}
		)

		return res.body
	};
};

const actions = crud('passenger', 'el pasajero', 'los pasajeros')

export default {
	...actions,
	loadWialonPassengers,
	loadClientPassengers,
	getClientPassenger,
	addClientPassenger,
	updateClientPassenger,
	removeClientPassenger,
	bulkSyncClientPassenger,
	loadPassengersBindigns,
	loadPassengers,
	getPassenger,
	addPassenger,
	updatePassenger,
	removePassenger,
	bulkSyncPassenger,
	createAddFile,
	updateFile,
	bulkSyncUpdate,
	bulkSyncUpdateClientPassenger,
	importPassengers,
	clientExcelLayout,
	clientImportFile,
	clientVerifyImportData,
	clientImportPassengers,
	getPassengerPermissions,
	savePassengerPermissions,
	createPassengerWithImage,
	updatePassengerWithImage,
	loadPersonalTransportationParameters,
	getPassengerModules,
	getWebPassengerPermissions,
	saveWebPassengerPermissions,
	createWialonPassengerNotification,
	getWialonNotificationStatus,
	toggleWialonNotificationStatus
}
