import crud from './_crud'
import { callApi } from 'utils/api'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'
import { getRol, createHeaders } from '../../utils/rol'

const entity = 'client'
const actions = crud(entity, 'el cliente', 'los clientes')

const updateSync = (item, files, image, shouldSkipStatusError = false) => {
	return async (dispatch, getState) => {
		const state = getState()
		console.log(files)
		const res = await callApi(`/${entity}/utils/update_sync`, {
			method: 'PATCH',
			json: false, // Importante para usar FormData
			body: {
				item: JSON.stringify(item),
				files: files,
				image: image,
			},
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: `Se actualizó el cliente correctamente.`,
			shouldSkipStatusError,
		})

		dispatch({
			type: `UPDATE_SYNC_${entity.toUpperCase()}`,
			response: res.body,
		})
	}
}

const addSync = (item, files, image) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/${entity}/utils/add_sync`, {
				method: 'POST',
				json: false, // Importante para usar FormData
				body: {
					item: JSON.stringify(item),
					files: files,
					image: image,
				},
				headers: {
					Authorization: state.app.user.token,
				},
				params: {
					will_create_user: item.will_create_user,
				},
				successMessage: `Se agregó el cliente correctamente.`,
			})

			dispatch({
				type: `ADD_SYNC_${entity.toUpperCase()}`,
				response: res.body,
			})
		} catch (e) {
			return
		}
	}
}

const loadPassengersBindings = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/passengers_bindings`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.client.token,
			},
		})

		dispatch({
			type: `GET_${entity.toUpperCase()}S_WIALON`,
			response: res.body,
		})

		let result = []

		if (res.body && Array.isArray(res.body)) {
			res.body.forEach((b) => {
				const day = new Date(b.t * 1000).toLocaleDateString()

				result.push({
					...b,
					tag_id: b.tag_id,
					t: b.t,
					day: day,
					u: b.u,
				})

				// let bind = result.findIndex((r) => r.tag_id == b.tag_id && r.day == day)

				// if (bind < 0) {
				// 	result.push({
				// 		tag_id: b.tag_id,
				// 		t: b.t,
				// 		day: day,
				// 		extra_bindings: [],
				// 		u: b.u,
				// 	})
				// } else {
				// 	if (!result[bind].tu) {
				// 		result[bind] = { ...result[bind], tu: b.t }
				// 	} else {
				// 		const extra_bind = result[bind].tu
				// 		result[bind] = {
				// 			...result[bind],
				// 			extra_bindings: [...result[bind].extra_bindings, extra_bind],
				// 			tu: b.t,
				// 		}
				// 	}
				// }
			})
		}

		return result
	}
}

const loadPassengersBindingsLocationReport = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const resp = await callApi('/client/passengers_bindings_location_report', {
			method: 'POST',
			headers: {
				Authorization: state.app.client.token,
			},
			body: JSON.stringify(options),
		})

		const data = resp.body.length
			? resp.body
					.map((record) => record.c)
					.map((record, i) => ({
						unit: record[0],
						passenger: record[1],
						bindTimeDate:
							typeof record[2] == 'object'
								? record[2]?.t?.split(' ')[0] || null
								: record[2]?.split(' ')[0],
						bindTimeHour:
							typeof record[2] == 'object'
								? record[2]?.t?.split(' ')[1] || null
								: record[2]?.split(' ')[1],
						bindLngLat: {
							x: record[3]?.x || null,
							y: record[3]?.y || null,
						},
						bindLocation: record[3]?.t || null,
						unbindTimeDate:
							typeof record[4] == 'object'
								? record[4]?.t?.split(' ')[0] || null
								: record[4]?.split(' ')[0],
						unbindTimeHour:
							typeof record[4] == 'object'
								? record[4]?.t?.split(' ')[1] || null
								: record[4]?.split(' ')[1],
						unbindLngLat: {
							x: record[5]?.x || null,
							y: record[5]?.y || null,
						},
						unbindLocation: record[5]?.t || null,
						passengerCode: record[6],
					}))
			: []
			
		return data
	}
}

const loadDriverClients = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/load_driver_clients`, {
			method: 'GET',
			headers: {
				Authorization: state.app.driver.token,
			},
			params: options.params,
		})

		dispatch({
			type: `LOAD_CLIENTS`,
			response: res.body,
		})

		return res.body
	}
}

const importClients = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/import_clients`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los clientes correctamente.`,
		})
		showSuccessMessage('Se registraron los clientes correctamente')
		return res.body
	}
}

const loadClientsByUsertype = ({ userType }) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/get_clients_by_usertype`, {
			method: 'GET',
			headers: {
				Authorization: state.app[userType].token,
			},
			params: {
				userType: userType,
				sys_company_id: state.app[userType].sys_company_id,
				[`${userType}_id`]: state.app[userType].id,
			},
		})

		dispatch({
			type: `GET_ALL_CLIENTS_BY_USERTYPE`,
			payload: res.body,
		})

		return res.body
	}
}

const getClientByCode = (identifier_code, options) => {
	return async (dispatch, getState) => {
		const state = getState()
		const params = new URLSearchParams()
		options.params.fields.forEach((field) => params.append('fields', field))

		const url = `/client/get_client_by_code/${identifier_code}${
			params ? `?${params}` : ''
		}`

		const res = await callApi(url, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const getClientPermissions = (clientId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client_permits/${clientId}`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const processGeofencesToImport = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			'/client/process_importing_client_wialon_geofences',
			{
				method: 'POST',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(data),
			}
		)

		return res.body
	}
}

const saveClientPermissions = (permissions, clientId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client_permits/${clientId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ permissions }),
		})

		return res.body
	}
}

const excelLayoutContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/generate_layout_excel_contacts`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

const importFileContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/import_data_from_excel_contacts`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				file: options.files,
			},
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const verifyImportDataContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/validate_data_from_import_contacts`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify(options.data),
		})

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const importContacts = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/import_contacts`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los contactos correctamente.`,
		})
		showSuccessMessage('Se registraron los contactos correctamente')
		return res.body
	}
}

const excelLayoutElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/client/generate_layout_excel_electronic_payment`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
			}
		)
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

const importFileElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/client/import_data_from_excel_electronic_payment`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.user.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const verifyImportDataElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/client/validate_data_from_import_electronic_payment`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

const importElectronicPayment = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client/import_electronic_payment`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los datos de pago correctamente.`,
		})
		showSuccessMessage('Se registraron los datos de pago correctamente')
		return res.body
	}
}

const getClientModules = () => {
	return async (dispatch, getState) => {
		const state = getState()

		const rol = getRol(state)
		const headers = createHeaders(state, rol)

		const res = await callApi(`/client_permits/client-modules`, {
			method: 'GET',
			headers,
		})

		return res.body
	}
}

const getWebClientPermissions = (clientId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const rol = getRol(state)
		const headers = createHeaders(state, rol)

		const res = await callApi(`/client_permits/web/${clientId}`, {
			method: 'GET',
			headers,
		})

		return res.body
	}
}

const saveWebClientPermissions = (permissions, clientId) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/client_permits/web/${clientId}`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({ web_permissions: permissions }),
		})

		return res.status === 200
	}
}

let customActions = {}
customActions = { ...customActions, updateSync }
customActions = { ...customActions, addSync }

export default {
	...actions,
	...customActions,
	loadPassengersBindings,
	loadPassengersBindingsLocationReport,
	loadDriverClients,
	importClients,
	loadClientsByUsertype,
	getClientByCode,
	getClientPermissions,
	saveClientPermissions,
	processGeofencesToImport,
	excelLayoutContacts,
	importFileContacts,
	verifyImportDataContacts,
	importContacts,
	excelLayoutElectronicPayment,
	importFileElectronicPayment,
	verifyImportDataElectronicPayment,
	importElectronicPayment,
	getClientModules,
	getWebClientPermissions,
	saveWebClientPermissions,
}
